// Components
import { Grid } from "@/client/components/ui/Grid";
import { Modal as ModalUI } from "@/client/components/ui/Modal";
import { ModalViewsSwitcher } from "@/client/components/modal/modal-views-switcher";

// Definitions
import type { ValueOf } from "@/common/types/basic";

// Utils
import { MODALS_BOOK } from "@/client/definitions/modals";

export type ModalType = {
  modalName: ValueOf<typeof MODALS_BOOK>;
  isOpen: boolean;
  autoWidth?: boolean;
  width?: number;
  testId?: string;
  closeBtnType?: "outside" | "inside";
  onClose: () => void;
};

export const Modal = (props: ModalType) => {
  const { isOpen = false, modalName, onClose, autoWidth, testId, width, closeBtnType } = props;
  const { md = true, lg = true } = Grid.useBreakpoint();
  const isTablet = !lg;
  const isMobile = !md;
  const modalUiGutter = isMobile ? "small" : isTablet ? "middle" : "large";
  return (
    <ModalUI
      gutter={modalUiGutter}
      open={isOpen}
      autoWidth={autoWidth}
      onCancel={onClose}
      testId={testId}
      width={width}
      closeBtnType={closeBtnType}
    >
      <ModalViewsSwitcher name={modalName} />
    </ModalUI>
  );
};
