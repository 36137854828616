// Core
import { ReactNode, ElementType } from "react";
import cx from "classnames";

// Components
import { Skeleton } from "@/client/components/ui/Skeleton";
import { Link, type LinkProps } from "@/client/components/others/link";
import { Badge, type BadgeProps } from "@/client/components/ui/Badge";
import {
  PopoverLightList,
  type PopoverLightListProps,
} from "@/client/components/others/popover-light-list/popover-light-list";
import { Icon } from "@/client/components/ui/Icon";

// Utils
import st from "./styles.module.css";

const LightListItemBase = (props: { children: ReactNode; loading?: boolean; testId?: string }) => {
  const { children, loading, testId } = props;
  const listItemStyle = cx(st["light-list-item"], {
    [st["light-list-item-loading"]]: Boolean(loading),
  });
  if (loading) {
    return (
      <li className={listItemStyle} data-testid={testId}>
        <Skeleton.Input active size="small" />
      </li>
    );
  }
  return (
    <li className={listItemStyle} data-testid={testId}>
      {children}
    </li>
  );
};
const LightListItemLink = (linkProps: {
  children: LinkProps["children"];
  link?: LinkProps & { component?: ElementType };
}) => {
  const { link, children } = linkProps;
  if (!link) {
    return <>{children}</>;
  }
  const { component: ItemLink = Link, href, ...otherLinkProps } = link;
  return (
    <ItemLink href={String(href)} {...otherLinkProps}>
      {children}
    </ItemLink>
  );
};
const LightListItemBadge = (badgeProps: {
  children: BadgeProps["children"];
  loading?: boolean;
  badge?: BadgeProps | null;
}) => {
  const { loading, badge = null, children = "" } = badgeProps;

  if (!badge) {
    return <>{children}</>;
  }

  if (loading) {
    return (
      <Badge {...badge} count={<Skeleton.Avatar size="small" shape="circle" />}>
        {children}
      </Badge>
    );
  }

  return <Badge {...badge}>{children}</Badge>;
};
const LightListItemPopover = (popoverProps: {
  children: PopoverLightListProps["children"];
  popover?: PopoverLightListProps["config"];
}) => {
  const { popover, children } = popoverProps;
  if (!popover) {
    return <>{children}</>;
  }
  return <PopoverLightList config={popover}>{children}</PopoverLightList>;
};

export type LightListItemType = {
  key: string;
  text?: ReactNode;
  icon?: string;
  link?: LinkProps & {
    component?: ElementType;
  };
  badge?: BadgeProps;
  popover?: PopoverLightListProps["config"];
  loading?: boolean;
  type?: "actions";
  itemTestId?: string;
};
const LightListItem = (props: LightListItemType) => {
  const { type, text, icon, link, badge, popover, loading, itemTestId } = props;

  const itemIconStyle = cx(st["light-list-item-icon"], {
    [st["light-list-item-icon-actions"]]: type === "actions",
  });
  const contentJSX = (
    <>
      {icon && <Icon name={icon} size="16" className={itemIconStyle} />}
      {text && <>{text}</>}
    </>
  );
  return (
    <LightListItemBase loading={loading && !badge} testId={itemTestId}>
      <LightListItemLink link={link}>
        <LightListItemBadge badge={badge} loading={loading}>
          <LightListItemPopover popover={popover}>{contentJSX}</LightListItemPopover>
        </LightListItemBadge>
      </LightListItemLink>
    </LightListItemBase>
  );
};

export type LightListProps = {
  mode?: "vertical" | "horizontal";
  items?: LightListItemType[];
  type?: "actions";
  testId?: string;
};
export const LightList = (props: LightListProps) => {
  const { mode = "horizontal", items = [], type, testId } = props;

  const listStyle = cx(st["light-list"], {
    [st["light-list-vertical"]]: mode === "vertical",
    [st["light-list-horizontal"]]: mode === "horizontal",
  });

  return (
    <ul className={listStyle} data-testid={testId}>
      {items.map((itemProps) => {
        const { key, ...otherProps } = itemProps;
        return <LightListItem key={key} type={type} {...otherProps} />;
      })}
    </ul>
  );
};
