"use client";

// Components
import { LayoutAppHeader3Cols } from "@/client/components/layouts/layout-app-header-3cols";
import { Title } from "@/client/components/ui/Typography/Title";
import { useTranslation } from "react-i18next";

export const HeaderBasket = () => {
  const { t } = useTranslation("basket");

  return (
    <LayoutAppHeader3Cols
      main={
        <Title
          tag="h1"
          font="second"
          size="24"
          fontWeight="700"
          alignmentHorizontal="center"
          color="dark"
        >
          {t("header.title")}
        </Title>
      }
      light
    />
  );
};
