// Definitions
import type { PopoverProps } from "antd";

// Components
import { Popover as PopoverAntd } from "antd";

// Utils
import st from "./styles.module.css";

export const Popover = (props: PopoverProps) => {
  return <PopoverAntd {...props} className={st.popover} />;
};
