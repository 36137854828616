// Core
import { createElement, useState } from "react";

// Definitions
import type { PropsWithChildren, ElementType } from "react";
import type { PopoverProps } from "antd";

// Components
import { Popover } from "@/client/components/ui/Popover";

// Utils
import st from "./styles.module.css";

export type PopoverLightListConfigType = {
  component: ElementType;
  trigger: PopoverProps["trigger"];
  placement: PopoverProps["placement"];
  popupOffset?: number[];
};
export type PopoverLightListProps = PropsWithChildren<{
  config?: PopoverLightListConfigType | null;
}>;

export const PopoverLightList = (props: PopoverLightListProps) => {
  const { config = null, children = null } = props;
  const [visible, setVisible] = useState(false);

  if (!config) {
    return <>{children}</>;
  }

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  return (
    <Popover
      openClassName={st["popover-open"]}
      content={createElement(config.component, { handleVisibleChange })}
      trigger={config.trigger}
      placement={config.placement}
      open={visible}
      onOpenChange={handleVisibleChange}
      destroyTooltipOnHide={false}
    >
      {children}
    </Popover>
  );
};
