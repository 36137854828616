// Components
import { LightList } from "@/client/components/others/light-list";

// Utils
import { profileActionRenderModel } from "./profile-action-render-model";

export const NavbarProfile = () => {
  const menuItems = profileActionRenderModel();

  return (
    <LightList testId="navbar-profile-list" mode="horizontal" type="actions" items={menuItems} />
  );
};
