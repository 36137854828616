// Components
import { Skeleton as AntSkeleton } from "antd";

// Definitions
import { SkeletonProps as AntSkeletonProps } from "antd";

export const Skeleton = (props: AntSkeletonProps) => {
  return <AntSkeleton {...props} />;
};

Skeleton.Button = AntSkeleton.Button;
Skeleton.Avatar = AntSkeleton.Avatar;
Skeleton.Input = AntSkeleton.Input;
Skeleton.Image = AntSkeleton.Image;
Skeleton.Node = AntSkeleton.Node;
