// Definitions
import type { BadgeProps as AntBadgeProps } from "antd";

// Components
import { Badge as AntBadge } from "antd";

// Utils
import st from "./styles.module.css";

export type BadgeProps = AntBadgeProps & {
  loading?: boolean;
  offset?: AntBadgeProps["offset"];
};

const BADGE_CONFIG_BASE: Pick<BadgeProps, "offset"> = {
  offset: [0, 0],
};

export const Badge = (props: BadgeProps) => {
  const { count, children, offset = BADGE_CONFIG_BASE.offset, title = "" } = props;

  return (
    <AntBadge title={title} count={count} offset={offset} className={st.badge}>
      {children}
    </AntBadge>
  );
};
