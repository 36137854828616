"use client";

// Store
import { useStore } from "@/client/hooks/use-store";

export const useModalCtrl = () => {
  const {
    controls: { pageModals, setOnPageModal },
  } = useStore();

  const setModalVisibilityByName = (name: string, visible: boolean) => {
    setOnPageModal(name, visible);
  };

  return {
    activeModals: pageModals,
    setModalVisibilityByName,
  };
};
