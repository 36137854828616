// Definitions
import { LightListItemType } from "@/client/components/others/light-list/light-list";

// Components
import { Link, LinkProps } from "@/client/components/others/link";
import { routes } from "@/client/definitions/book";
import { NavbarUserPopover } from "@/client/domains/user/navigation/components/navbar-user-popover";

export const profileActionRenderModel = (): LightListItemType[] => {
  const CellLink = (linkProps: LinkProps) => Link({ ...linkProps, type: "default" });
  return [
    // toDo: wait for an implementation of the notifications system
    // {
    //   key: "1",
    //   icon: "Bell",
    // },
    {
      key: "2",
      icon: "UserProfile",
      link: {
        href: routes.settings,
        component: CellLink,
      },
      popover: {
        component: NavbarUserPopover,
        trigger: "hover",
        placement: "bottom",
      },
      itemTestId: "user-area-navigation-item",
    },
    {
      key: "3",
      icon: "Support",
    },
    {
      key: "4",
      icon: "Cart",
    },
  ];
};
